import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { JWT } from '../helpers/jwt';

@Injectable()
export class AuthService {
  public apiEndpoint = 'https://app.dev.keenetic.cloud/api/v1';
  public token: string;
  public uid: string;

  constructor(private http: HttpClient) {
    this.setToken(localStorage.getItem('token') || sessionStorage.getItem('token') || null);
  }

  setToken(token) {
    this.token = token;
    if (this.token) {
      const jwt = JWT.parse(this.token);
      this.uid = jwt.uid || null;
    }
  }


  getFingeprint() {
    return Md5.hashStr(navigator.platform +
      navigator.vendor +
      new Date().getTimezoneOffset() / 60 +
      navigator.language +
      navigator.hardwareConcurrency +
      screen.height + screen.width + screen.colorDepth).toString();
  }

  /*** Check that email is exists */
  check(username: string) {
    // username = encodeURIComponent(username);
    return this.http.get(this.apiEndpoint + '/auth/check?username=' + encodeURIComponent(username))
      .toPromise();
  }

  login(username: string, password: string) {
    return this.http.post(this.apiEndpoint + '/auth/login',
      {
        'username': username,
        'password': password
      }, {
      headers: new HttpHeaders({})
    })
      .toPromise();
  }

  ssoAuth(queries: string) {
    return this.http.get(this.apiEndpoint + '/sso/auth?' + queries,
      {
        headers: new HttpHeaders({})
      })
      .toPromise();
  }

  eula(lang) {
    return this.http.get(
      '/files/eula?lang=' + lang,
      { responseType: 'text', observe: 'response' }
    ).toPromise()
  }

  register(email: string, password: string, name: string, eulaVersion: string) {
    return this.http.post(this.apiEndpoint + '/users',
      {
        'name': name,
        'email': email,
        'password': password,
        'eula_version': eulaVersion,
      }, {
      headers: new HttpHeaders({
      })
    })
      .toPromise();
  }

  logout() {
    this.token = null;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }

  clear() {
    this.token = null;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('cid');
    sessionStorage.removeItem('cid');
    localStorage.removeItem('locale');
    sessionStorage.removeItem('locale');
  }

  restorePassword(email: string): Promise<any> {
    return this.http.get(this.apiEndpoint + '/auth/recovery?email=' + encodeURIComponent(email))
      .toPromise();
  }

  recovery(password: string, code: string): Promise<any> {
    return this.http.put(this.apiEndpoint + '/auth/recovery/' + code, {
      'password': password
    }).toPromise();
  }

  getCountries(lang: string): Promise<any> {
    return this.http.get('/countries-list?lang=' + lang)
      .toPromise();
  }

  isAuthorized(): boolean {
    if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
      return true;
    }
    return false;
  }

}
